body {
    font-family: Montserrat, sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1.3;
    color: #131d22;
}
input {
    font-weight: 400;
    font-size: 14;
}
input::placeholder {
    font-weight: 600;
    font-size: 12;
    color: #b0b9bf;
}
textarea {
    font-family: Montserrat, sans-serif;
    line-height: 1.3;
}
textarea::placeholder {
    font-weight: 600;
    font-size: 12;
    color: #b0b9bf;
}
