body {
  color: #131d22;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  line-height: 1.3;
}

input {
  font-size: 14px;
  font-weight: 400;
}

input::placeholder {
  color: #b0b9bf;
  font-size: 12px;
  font-weight: 600;
}

textarea {
  font-family: Montserrat, sans-serif;
  line-height: 1.3;
}

textarea::placeholder {
  color: #b0b9bf;
  font-size: 12px;
  font-weight: 600;
}
/*# sourceMappingURL=index.1f7ddb6c.css.map */
